<script setup lang="ts">
    import { useCartQuery } from '@/composables/cart';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import Button from 'primevue/button';
    import { useCompanyQuery } from '@/composables/company';
    import Textarea from 'primevue/textarea';
    import InputText from 'primevue/inputtext';
    import { computed, ref, type Ref } from 'vue';
    import { ORDER_NOTE_MAX_LENGTH, ORDER_REFERENCE_MAX_LENGTH, PaymentMethod } from '@containex/portal-backend-dto';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import LineItemGroupDisplay from '@/checkout/components/LineItemGroupDisplay.vue';
    import { isMobile } from '@/util/breakpoints';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        paymentMethod: PaymentMethod | undefined;
    }>();

    const emits = defineEmits<{
        'delivery-step': [event: Event];
        'order-note': [string | undefined];
        'order-reference': [string | undefined];
    }>();

    const orderNote: Ref<string | undefined> = ref(undefined);
    const orderReference: Ref<string | undefined> = ref(undefined);

    const { t } = useI18n();
    const { cart } = useCartQuery();
    const { company, companyUid } = useCompanyQuery();
    const cartBillingAddress = computed(() => company.value?.billingAddress);
    const groupData = computed(() => {
        const groups = cart.value?.lineItemGroups ?? [];
        const allItems = cart.value?.items ?? [];

        return groups.map((group) => ({
            group,
            items: allItems.filter((item) => item.line_item_group_id === group.id),
        }));
    });

    function updateOrderNote(value: string | undefined): void {
        emits('order-note', value);
    }

    function updateOrderReference(value: string | undefined): void {
        emits('order-reference', value);
    }
</script>

<template>
    <div class="summary-container">
        <div class="checkout-sections">
            <CheckoutSectionCard>
                <template #header>
                    <div class="header-content">{{ t('CHECKOUT.PURCHASER.TITLE') }}</div>
                </template>
                <template #content>
                    <div class="billing-information-content">
                        <div class="left-side">
                            <h3 class="text-base-semibold-line-height-auto">{{ t('CHECKOUT.PURCHASER.COMPANY') }}</h3>
                            <div>
                                <span>{{ company?.companyName }}</span>
                                <br />
                                <span v-if="company?.externalId != null">
                                    {{ t('CHECKOUT.PURCHASER.COMPANY_ID') }}: {{ company.externalId }}
                                </span>
                                <br />
                                <span v-if="companyUid != null">{{ t('COMMON.UID') }}: {{ companyUid }}</span>
                            </div>
                        </div>
                        <div class="right-side">
                            <h3 class="text-base-semibold-line-height-auto">
                                {{ t('CHECKOUT.PURCHASER.COMPANY_ADDRESS') }}
                            </h3>
                            <AddressDisplay v-if="company?.billingAddress != null" :address="company.billingAddress" />
                        </div>
                    </div>
                </template>
            </CheckoutSectionCard>
            <CheckoutSectionCard class="delivery-option-display">
                <template #header>
                    <div class="header-content">
                        <span>{{ t('CART.STEPS.DELIVERY.STEP') }}</span>
                        <Button
                            text
                            class="edit-btn"
                            :label="isMobile ? '' : t('FORM.EDIT')"
                            icon="pi pi-pencil"
                            @click="(event) => emits('delivery-step', event)"
                        />
                    </div>
                </template>
                <template #content>
                    <div class="cart-item-list">
                        <LineItemGroupDisplay
                            v-for="data in groupData"
                            :key="data.group.id"
                            :group="data.group"
                            :items="data.items"
                            :is-rental="true"
                        />
                    </div>
                </template>
            </CheckoutSectionCard>
            <CheckoutSectionCard>
                <template #header>
                    <div class="header-content">
                        <span>{{ t('CHECKOUT.PAYMENT.TITLE') }}</span>
                    </div>
                </template>
                <template #content>
                    <div class="billing-information-content">
                        <div class="left-side">
                            <h3 class="text-base-semibold-line-height-auto">{{ t('CART.STEPS.BILLING_ADDRESS') }}</h3>
                            <AddressDisplay v-if="cartBillingAddress != null" :address="cartBillingAddress" />
                        </div>
                        <div class="right-side">
                            <h3 class="text-base-semibold-line-height-auto">
                                {{ t('CHECKOUT.SUMMARY.PAYMENT_METHOD_TITLE') }}
                            </h3>
                            <p>{{ t('CHECKOUT.PAYMENT.' + props.paymentMethod?.toString() + '.TITLE') }}</p>
                        </div>
                    </div>
                </template>
            </CheckoutSectionCard>
        </div>
        <div class="box-container">
            <h3 class="text-xl-bold-line-height-auto">{{ t('CART.STEPS.ORDER_REFERENCE') }}</h3>
            <InputText
                v-model="orderReference"
                class="half-width"
                :placeholder="t('CART.STEPS.ORDER_REFERENCE_PLACEHOLDER')"
                :invalid="orderReference != null && orderReference.length > ORDER_REFERENCE_MAX_LENGTH"
                @update:model-value="updateOrderReference"
            />
            <small
                v-if="orderReference != null && orderReference.length > ORDER_REFERENCE_MAX_LENGTH"
                class="box-error"
            >
                {{ t('CART.STEPS.ORDER_REFERENCE_ERROR', { maxLength: ORDER_REFERENCE_MAX_LENGTH }) }}
            </small>
        </div>

        <div class="box-container">
            <h3 class="text-xl-bold-line-height-auto">{{ t('CART.STEPS.ORDER_NOTE') }}</h3>
            <Textarea
                v-model="orderNote"
                auto-resize
                rows="4"
                class="half-width"
                :placeholder="t('CART.STEPS.ORDER_NOTE_PLACEHOLDER')"
                :invalid="orderNote != null && orderNote.length > ORDER_NOTE_MAX_LENGTH"
                @update:model-value="updateOrderNote"
            />
            <small v-if="orderNote != null && orderNote.length > ORDER_NOTE_MAX_LENGTH" class="box-error">
                {{ t('CART.STEPS.ORDER_NOTE_ERROR', { maxLength: ORDER_NOTE_MAX_LENGTH }) }}
            </small>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    p {
        margin: 0;
    }

    .delivery-option-display {
        flex: 1;
    }

    .checkout-sections {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
        flex: 2;
    }

    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .billing-information-content {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        gap: main.$spacing-5;

        .left-side,
        .right-side {
            display: flex;
            flex-direction: column;
            gap: main.$spacing-2;
            flex: 1;
            border: 1px solid main.$color-border-gray;
            padding: main.$spacing-5;
        }
    }

    .half-width {
        width: 100%;
    }

    @include main.for-breakpoint-md {
        .billing-information-content {
            flex-direction: row;
        }

        .half-width {
            width: 50%;
        }
    }

    .address-container {
        background-color: main.$color-background-bluegray;
        min-width: 13rem;
    }

    .summary-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: main.$spacing-5;
    }

    .cart-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
        width: 100%;
    }

    .box-container {
        background: main.$vt-c-white;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
        padding: main.$spacing-5;
        border-radius: 4px;
    }

    .box-error {
        color: main.$errorColor;
    }
</style>
